<!--  -->
<template>
  <div class="mui-content" v-if="settmentInfo">
    <div class="address" v-if="settmentInfo.fullAddress">
      <div class="point"><span class="icon-location"></span></div>
      <div class="info">
        <span>收货人: {{ settmentInfo.name }}</span>
        <span class="mi-pull-right">{{ settmentInfo.phone }}</span>
      </div>
      <div class="detail">
        <span>收货地址: {{ settmentInfo.fullAddress }}</span>
      </div>
    </div>
    <div class="product-list">
      <div class="pro-item" v-for="(item, index) in settmentInfo.courseList" :key="index">
        <img class="pro-image" :src="item.courseImg" />
        <div class="pro-cont">
          <div class="pro-name">{{ item.courseTile }}</div>
          <!-- <div class="spesc">25mlx2</div>
          <div class="other">月限购</div> -->
          <div class="albtm">
            <div class="price">￥<span>{{ item.coursePrice }}</span></div>
            <div class="count">x<span>{{ item.courseCount }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <van-cell-group round class="mt10">
      <van-field v-model="message" rows="5" label="买家留言" type="textarea" placeholder="请输入留言" />
    </van-cell-group>

    <van-radio-group v-model="payType">
      <van-cell-group round class="mt10 pay-cont">
        <van-cell title="支付方式" :border="false" />
        <van-cell title="微信支付" clickable @click="payType = 1" icon="wechat-pay" :border="false">
          <template #right-icon>
            <van-radio :name="1" checked-color="#FF99A1" />
          </template>
        </van-cell>
        <van-cell title="支付宝支付" clickable @click="payType = 2" icon="alipay" :border="false" v-show="isApp === '1'">
          <template #right-icon>
            <van-radio :name="2" checked-color="#FF99A1" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="footer">
      <div class="pay-info">
        <div class="wait-pay">待付款：<span>￥</span><span class="number">{{ settmentInfo.payAmount }}</span></div>
        <div class="total-num">共1件</div>
      </div>
      <!-- <van-button plain color="#FF98A0">取消订单</van-button> -->
      <van-button color="#FF98A0" @click="onSubmit()">提交订单</van-button>
    </div>
  </div>
</template>

<script>
import { wxPay } from "../../utils/wxPay";
import { deliveryMessage } from "../../utils/appUtil"
export default {
  name: '',
  data() {
    return {
      addressInfo: null,
      message: "",
      payType: 0,
      isApp: '0',
      proId: 0,
      settmentInfo: null,
      orderId: "", orderNum: ""
    }
  },
  created() {
    let { proId, isapp } = this.$route.query;
    this.isApp = isapp;
    if (isapp !== '1') this.payType = 1;
    if (proId) {
      this.proId = proId;
      this.getSettlementInfo();
    }
  },
  mounted() {
    window.orderSuccess = () => {
      this.$router.push("/course/success?orderId=" + this.orderId + "&orderNum=" + this.orderNum + "&isapp=1")
    }
  },
  methods: {
    //获取结算信息
    getSettlementInfo() {
      this.$toast.loading({ duration: 0, message: '加载中...', overlay: true });
      this.get("/GroupBuy/OnlineCourse/GetConfirmCourseOrder?", {
        courseList: JSON.stringify([{ courseId: this.proId, courseCount: 1 }])
      }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.settmentInfo = res.response;
        }
      }).catch(() => this.$toast.clear())
    },
    //提交订单
    onSubmit() {
      this.$toast.loading({ duration: 0, message: '订单生成中...', overlay: true });
      this.post("/GroupBuy/OnlineCourse/CreateCourseOrder", {
        courseList: JSON.stringify([{ courseId: this.proId, courseCount: 1 }]),
        remark: this.message
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let { orderId, orderNum } = res.response;
          if (this.isApp === '1') {
            this.orderId = orderId;
            this.orderNum = orderNum;
            let message = { action: "jumpExplosivePay", data: { orderId, orderNum, payType: this.payType } };
            deliveryMessage(message);
          } else {
            this.getPayInfo(orderId, orderNum);
          }
        } else {
          this.$dialog.alert({
            title: '提示',
            message: res.msg,
          })
        }
      }).catch(() => this.$toast.clear())
    },
    //获取支付信息
    getPayInfo(orderId, orderNum) {
      this.$toast.loading({ duration: 0, message: '支付中...', overlay: true });
      this.post("/GroupBuy/OnlineCourse/PayCourseOrder", {
        orderId: orderId,
        payType: this.payType,
        sourceType: 2
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response.wechat;
          wxPay({
            appId: data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
            paySign: data.sign, signType: data.signType, package: data.package
          }).then(() => {
            this.$toast("支付成功");
            this.$router.push("/course/success?orderId=" + orderId + "&orderNum=" + orderNum + "&isapp=" + this.isApp)
          }).catch(msg => {
            this.$dialog.alert({
              title: '提示',
              message: res.msg
            }).then(res => {
              this.$router.push("/Recharge/order_detail?orderId=" + orderId + "&orderType=13&isapp=" + this.isApp);
            })
          })
        } else {
          this.$dialog.alert({
            title: '提示',
            message: res.msg,
          })
        }
      }).catch(() => this.$toast.clear())
    }
  }
}
</script>
<style lang='scss' scoped>
.mt10 {
  margin-top: 10px;
}

.mui-content {
  min-height: 100%;
  padding: 10px 15px 80px;
  background-color: #F8F8F8;
  text-align: left;

  .address {
    position: relative;
    border-radius: 6px;
    padding: 17px 15px 17px 58px;
    background-color: #fff;

    .point {
      position: absolute;
      top: 50%;
      left: 11px;
      width: 33px;
      height: 33px;
      text-align: center;
      margin-top: -16px;
      border-radius: 50%;
      background-color: #ffb5bb;

      .icon-location {
        color: #fff;
        font-size: 20px;
        line-height: 33px;

        &:before {
          content: "\e9f0";
        }
      }
    }

    .info {
      span {
        color: #666;
      }

      .mi-pull-right {
        float: right;
      }
    }

    .detail {
      padding-top: 3px;

      span {
        color: #999;
      }
    }
  }

  .product-list {
    padding-top: 10px;

    .brand-name {
      padding-left: 15px;
      line-height: 44px;
      border-bottom: 1px solid #F1F1F1;
      border-radius: 6px 6px 0 0;
      background-color: #fff;
    }

    .pro-item {
      display: flex;
      position: relative;
      padding: 11px 15px;
      background-color: #fff;

      .pro-image {
        width: 125px;
        height: 125px;
        margin-right: 10px;
      }

      .pro-cont {
        flex: 1;
        color: #636363;
        display: inline-flex;
        flex-direction: column;

        .pro-name {

          font-size: 14px;
        }

        .spesc {
          padding: 5px 0;
        }

        .other {
          color: #999;
        }

        .albtm {
          display: flex;
          align-items: center;
          align-items: center;
          justify-content: space-between;
          margin-top: auto;

          .price {
            color: #FF98A0;
            font-size: 14px;

            span {
              font-size: 18px;
            }
          }

          .count {
            color: #B5B5B5;

            span {
              font-size: 14px;
            }
          }
        }
      }

      &:last-child {
        border-radius: 0 0 6px 6px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAA9AgMAAAD69FDPAAAACVBMVEUAAAAAAAAAAACDY+nAAAAAA3RSTlMIAQTqE2A+AAAAGklEQVQI12MIIAImMExgWADCeGADECowkAAAXQURodU+YFsAAAAASUVORK5CYII=) repeat-x;
        background-size: auto 100%;
      }
    }
  }

  .pay-cont {
    .van-cell {
      /deep/.van-icon {}

      /deep/.van-icon-wechat-pay {
        color: #4CBC00;
        font-size: 30px;
      }

      /deep/.van-icon-alipay {
        color: #009FE8;
        font-size: 30px;
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 44px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 -2px 6px rgba($color: #000000, $alpha: 0.2);

    .pay-info {
      flex: 1;

      .wait-pay {
        color: #666;
        font-size: 14px;

        span {
          color: #FF98A0;
        }

        .number {
          font-size: 18px;
        }
      }

      .total-num {
        color: #B5B5B5;
        font-size: 11px;
      }
    }

    .van-button {
      height: 33px;
      border-radius: 33px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>